import axios from 'axios';
import jwtDefaultConfig from '../utility/constants/jwtDefaultConfig';
import { getCookie, removeCookie, setCookie } from '../utility/utilities';

const jwtConfig = { ...jwtDefaultConfig };

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/v1',
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // ** Get token from Cookies
    const accessToken = getToken();
    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      // ** eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${jwtConfig.tokenType} ${accessToken}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// ** For Refreshing Token
var isAlreadyFetchingAccessToken = false;

// ** For Refreshing Token
var subscribers = [];

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error) => {
    const { config, response } = error;
    const originalRequest = config;

    if (response && response.status === 401) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;

        await refreshToken()
          .then((res) => {
            if (res.status === 200) {
              isAlreadyFetchingAccessToken = false;
              // ** Update accessToken in Cookie
              setToken(res.data.data.item.accessToken);
              onAccessTokenFetched(res.data.data.item.accessToken);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              removeCookie(jwtConfig.storageTokenKeyName);
              removeCookie(jwtConfig.storageRefreshTokenKeyName);
              window.location.reload();
            }
          });
      }
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken) => {
          // ** Make sure to assign accessToken according to your response.
          // ** Check: https://pixinvent.ticksy.com/ticket/2413870
          // ** Change Authorization header
          originalRequest.headers.Authorization = `${jwtConfig.tokenType} ${accessToken}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  }
);

const getToken = () => {
  return getCookie(jwtConfig.storageTokenKeyName);
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const onAccessTokenFetched = (accessToken) => {
  subscribers = subscribers.filter((callback) => callback(accessToken));
};

const getRefreshToken = () => {
  return getCookie(jwtConfig.storageRefreshTokenKeyName);
};

const setToken = (value) => {
  setCookie(jwtConfig.storageTokenKeyName, value);
};

const refreshToken = () => {
  return axios.post(
    process.env.REACT_APP_API_URL + '/v1' + jwtConfig.refreshEndpoint,
    {
      refreshToken: getRefreshToken(),
    }
  );
};

export default instance;
